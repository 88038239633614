<template>
  <v-container>
    <v-card>
      <v-card-title class="font-weight-medium">
        <v-btn class="pa-2" text @click="$router.back()" color="error">
          <v-icon left>mdi-arrow-left</v-icon>
          Go Back
        </v-btn>
        <v-icon class="mr-2">mdi-credit-card</v-icon>
        Create or Update Cab Request
        <v-btn
          v-if="
            cabRequestDetails &&
            cabRequestDetails.status &&
            cabRequestDetails.status.toLowerCase() !== 'open'
          "
          rounded
          color="primary"
          elevation="9"
          small
          x-small
          class="ml-2"
          @click="approvalEntriesDialog = true"
          ><v-icon left> mdi-file-document-plus</v-icon>approval entries</v-btn
        >
      </v-card-title>
      <v-divider />

      <v-row class="flex-nowrap">
        <v-col cols="12" md="12" sm="12">
          <v-card flat class="pa-3">
            <!--BEGIN form-->
            <v-card-text class="mb-n6">
              <v-form v-model="isValid" ref="entryForm">
                <v-row>
                  <!--Pickup-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-text-field
                      dense
                      outlined
                      required
                      :rules="[rules.required]"
                      v-model="formData.fromLocation"
                      :readonly="formData.status !== 'Open'"
                    >
                      <template v-slot:label>
                        <label>Pick-up<span class="required">*</span></label>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!--DropOff-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-text-field
                      dense
                      outlined
                      required
                      :rules="[rules.required]"
                      v-model="formData.toLocation"
                      :readonly="formData.status !== 'Open'"
                    >
                      <template v-slot:label>
                        <label>Drop-off<span class="required">*</span></label>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!--DropOff-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-text-field
                      dense
                      outlined
                      v-model="formData.stops"
                      :readonly="formData.status !== 'Open'"
                    >
                      <template v-slot:label>
                        <label>Stops<span class="required">*</span></label>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--END Reference Number-->

                  <!--BEGIN start date pickers-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6 mt-2">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :readonly="formData.status !== 'Open'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData.travelDate"
                          prepend-inner-icon="mdi-calendar"
                          color="primary"
                          dense
                          outlined
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                          :readonly="formData.status !== 'Open'"
                        >
                          <template v-slot:label>
                            <label>
                              Travel date<span class="required">*</span>
                            </label>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.travelDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!--Stops-->
                  <v-col cols="12" md="6" sm="12" class="mb-n6">
                    <v-text-field
                      dense
                      outlined
                      label="Estimate Amount"
                      v-model.number="formData.estimateAmount"
                      type="number"
                      :readonly="formData.status !== 'Open'"
                    />
                  </v-col>
                  <!--Stops-->
                  <!--Cab dimension panel-->
                  <v-row no-gutters class="mb-5">
                    <v-col cols="12">
                      <v-expansion-panels v-model="panel">
                        <v-expansion-panel>
                          <!--BEGIN dimensions-->
                          <v-expansion-panel-header>
                            <span class="table-font"
                              >Click to view &amp; add available
                              dimensions</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <little-cab-dimensions
                              :dimensionValues="dimensionValues"
                              :formData="formData"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                  <!--END dimension panel-->

                  <!--BEGIN purpose of the purpose of the event-->
                  <v-col cols="12" md="12" sm="12" class="mb-n6 mt-2">
                    <v-textarea
                      rows="4"
                      dense
                      outlined
                      required
                      auto-grow
                      clear-icon="mdi-close-circle"
                      :rules="[rules.required]"
                      v-model="formData.description"
                      :readonly="formData.status !== 'Open'"
                    >
                      <template v-slot:label>
                        <label
                          >Purpose/Description.<span class="required"
                            >*</span
                          ></label
                        >
                      </template>
                    </v-textarea>
                  </v-col>

                  <!--Return trip -->
                  <v-col
                    v-if="!$route.params.code"
                    cols="12"
                    md="4"
                    sm="12"
                    class=" "
                  >
                    <v-checkbox
                      class=""
                      color="primary"
                      v-model="formData.returnTrip"
                      label="Include return trip"
                    ></v-checkbox>
                  </v-col>
                  <!--END purpose-->
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" sm="12" class="mb-4">
                    <v-btn
                      color="primary"
                      class="ml-2 white--text"
                      @click="saveRequest"
                    >
                      <v-icon dark
                        >mdi-{{
                          $route.params.code ? "update" : "plus"
                        }}</v-icon
                      >Save Request
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <!--END form-->
            </v-card-text>
            <div
              v-if="
                cabRequestDetails &&
                cabRequestDetails.status &&
                cabRequestDetails.status.toLowerCase() !== 'open'
              "
              class="mt-4"
            >
              <v-divider></v-divider>
              <v-dialog v-model="approvalEntriesDialog" width="90%">
                <v-card>
                  <v-card-title class="">
                    <v-spacer></v-spacer>
                    <v-btn
                      fab
                      outlined
                      color="red"
                      small
                      @click="approvalEntriesDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <CabApprovalEntries
                      :approvalEntries="approvalEntries"
                      v-if="
                        cabRequestDetails &&
                        cabRequestDetails.status &&
                        cabRequestDetails.status !== 'open'
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-divider />
    </v-card>
  </v-container>
</template>

<script>
import LittleCabDimensions from "./LittleCabDimensions.vue";
import CabApprovalEntries from "./CabApprovalEntries.vue";

export default {
  name: "LittleCabCard",
  components: {
    LittleCabDimensions,
    CabApprovalEntries,
  },
  data: function () {
    return {
      isValid: true,
      panel: [0],
      formData: {
        no: "",
        description: "",
        fromLocation: "",
        toLocation: "",
        travelDate: "",
        distanceTraveled: "",
        returnTrip: false,
        stops: "",
        estimateAmount: 0,
        employee: null,
        status: "Open",
        //dimensions, speedKey and fund
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
        shortcutDimension5Code: "",
        shortcutDimension6Code: "",
        shortcutDimension7Code: "",
        shortcutDimension8Code: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      approvalEntriesDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("littlecab/getDimensionValues");

      // v.$store.dispatch("littlecab/getCurrencies");
      v.$store.dispatch("littlecab/getfunds");
      v.$store.dispatch("littlecab/getdimensionSpeedKey");

      if (v.$route.params.code) {
        v.$store.dispatch("littlecab/getApprovalEntries", v.$route.params.code);
      }
    });
  },

  created() {
    if (this.$route.params.code) {
      this.$store.dispatch(
        "littlecab/getCabRequestsDetails",
        btoa(this.$route.params.code)
      );
    }
  },

  computed: {
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    startDateByType() {
      const today = new Date();
      return today.toISOString().substr(0, 10);
    },
    employee() {
      return this.$store.getters["auth/user"].profile;
    },
    cabRequestDetails() {
      return this.$store.getters["littlecab/cabRequestsDetails"];
    },
    dimensionValues() {
      return this.$store.getters["littlecab/littleCabGetters"]("dimensions");
    },
    approvalEntries() {
      return this.$store.getters["littlecab/littleCabGetters"](
        "approvalEntries"
      ).data;
    },
  },

  methods: {
    saveRequest: function () {
      this.formData.employee = this.employee;
      if (this.$route.params.code) {
        this.$store
          .dispatch("littlecab/updateCabRequest", this.formData)
          .then(() => {
            this.$router.push({ path: "/littlecab/applications" });
          });
      } else {
        this.$store
          .dispatch("littlecab/createCabRequest", this.formData)
          .then(() => {
            this.$router.push({ path: "/littlecab/applications" });
          });
      }
    },
    closeApprovalEntriesDialog() {
      this.approvalEntriesDialog = false;
    },
  },

  watch: {
    cabRequestDetails: {
      handler: function () {
        if (this.$route.params.code) {
          this.formData = { ...this.cabRequestDetails };
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../styles/style.css");
.required {
  color: red;
}
</style>
